<template>
  <b-container fluid>
    <b-skeleton-wrapper
      v-if="spinner"
      :loading="spinner"
    >
      <template #loading>
        <b-skeleton type="button" />
        <b-row class="mt-1">
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
        </b-row>
        <b-skeleton
          class="mt-1"
          type="button"
        />
        <b-row class="mt-1">
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
          <b-col
            lg="12"
            xl="12"
            md="12"
            sm="12"
            class="mt-1"
          >
            <b-skeleton type="input" />
          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <b-skeleton type="button" />
          </b-col>
          <b-col
            lg="6"
            md="6"
            xl="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
          <b-col
            lg="6"
            md="6"
            xl="6"
            sm="12"
          >
            <b-skeleton type="input" />
          </b-col>
        </b-row>
      </template>
    </b-skeleton-wrapper>
    <div v-else>
      <ValidationObserver ref="screenForm">
        <!--Screen Basic Section-->
        <h3 class="font-weight-bold mb-1">
          Basic Information
        </h3>
        <b-form-row>
          <!-- Screen Name input-->
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Screen name"
              vid="screen-name"
              rules="required"
            >
              <b-form-group
                label-for="screen-name"
                label="Enter Screen Name"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="screen-name"
                  v-model="screen.name"
                  placeholder="e.g Black 32 inch Samsung"
                  variant="primary"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Screen Venue Type selector-->
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Screen Venue"
              vid="venue-type"
              rules="required"
            >
              <b-form-group
                label="Select Venue"
                label-for="venue-type"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  v-model="screen.venueId"
                  input-id="venue-type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.getters['venues/getVenue'].data.results"
                  :state="errors.length > 0 ? false:null"
                  label="venue_name"
                  :reduce="allVenues => allVenues.id"
                  placeholder="Select Venue"
                >

                  <template #list-footer>
                    <b-row class="justify-content-center mx-auto">
                      <b-col
                        cols="12"
                        class="mx-auto"
                      >
                        <b-pagination
                          :value="paginationObject.offset"
                          :total-rows="paginationObject.total"
                          :per-page="paginationObject.limit"
                          class="mb-0 mt-1 mt-sm-0 ml-5"
                          hide-goto-end-buttons
                          size="sm"
                          pills
                          @change="pageChanged($event)"
                        />
                      </b-col>
                    </b-row>
                  </template>
                </v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Screen Operational hours -->
          <b-col cols="12">
            <h3 class="font-weight-bold">
              Screen Operating Hours
            </h3>
          </b-col>
          <!-- Start Time selector-->
          <b-col
            lg="6"
            md="6"
            xl="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Start Time"
              vid="screen-start-time"
              rules="required"
            >
              <b-form-group
                label-for="screen-start-time"
                label="Enter Start Time"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-timepicker
                  id="screen-start-time"
                  v-model="screen.op_start_time"
                  placeholder="Screen Start Time"
                  variant="primary"
                  locale="en"
                  :show-seconds="false"
                  :state="startTimer"
                  :hour12="false"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- End Time selector-->
          <b-col
            lg="6"
            md="6"
            xl="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="End Time"
              vid="screen-end-time"
              rules="required"
            >
              <b-form-group
                label-for="screen-end-time"
                label="Enter End Time"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-timepicker
                  id="screen-end-time"
                  v-model="screen.op_end_time"
                  placeholder="Screen End Time"
                  variant="primary"
                  locale="en"
                  :show-seconds="false"
                  :state="endTimer"
                  :hour12="false"
                  :disabled="!endTimeField"
                  @input="compareTimes"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Monetisation -->
          <b-col cols="12">
            <h3 class="font-weight-bold">
              Monetisation
            </h3>
          </b-col>
          <!-- Monetisation Check-->
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-form-checkbox
              v-model="screen.ssp_opt_in"
              variant="primary"
              name="static-support"
              :value="true"
              :unchecked-value="false"
            >
              Monetisation
            </b-form-checkbox>
          </b-col>

          <b-col
            cols="12"
            class="bg-white mt-2"
          >
            <CardQuillEditor
              name="Screen Audience Profile"
              @audienceProfile="audienceProfile"
            />
          </b-col>

          <b-col
            class="mt-1"
            cols="12"
            offset="12"
            align-items-end
            align-self="end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              filled
              style="float: right;"
              variant="primary"
              :disabled="submitData"
              @click="validationForm()"
            >
              <div
                v-if="submitData"
                class="spinner"
              >
                <b-spinner
                  small
                  class="d-inline-block"
                />
                <span class="sr-only">Loading...</span>
              </div>
              Submit
            </b-button>
          </b-col>
        </b-form-row>
      </ValidationObserver>
    </div>
    <AddVenue
      ref="add-venue"
      @reloadVenues="reloadVenue()"
    />
  </b-container>
</template>
<script>

import {
  BContainer, BFormRow, BCol, BFormGroup, BRow, BFormInput,
  BSkeletonWrapper, BSkeleton,
  BFormTimepicker, BFormCheckbox, BButton, BSpinner,
  BPagination,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  required, lng, lat, email,
} from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.au'
import Ripple from 'vue-ripple-directive'
import AddVenue from '@/components/venues/AddVenue.vue'
import { showErrorMessage, showToast } from '@/common/global/functions'
import CardQuillEditor from '../common/CardQuillEditor.vue'

// Import Quill styles (choose one theme)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BPagination,
    BFormRow,
    BCol,
    BRow,
    BContainer,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BSkeletonWrapper,
    BSkeleton,
    BFormInput,
    BFormTimepicker,
    BFormCheckbox,
    BButton,
    BSpinner,
    AddVenue,
    CardQuillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      lng,
      lat,
      email,
      endTimer: null,
      startTimer: null,
      stringLength: 20,
      stringCharacters: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      screen: {
        name: '',
        venueId: '',
        is_banner_ad_mode_enabled: true,
        video_support: true,
        mac_address: '',
        image_support: true,
        impressions: 0.0,
        ssp_opt_in: true,
        op_end_time: null,
        op_start_time: null,
        audience_profile: null,
      },
      submitData: false,
      allVenues: [],
      spinner: false,
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
        searchQuery: '',
      },
    }
  },
  computed: {
    venue() {
      return this.$store.getters['venues/getVenue']
    },
    endTimeField() {
      if (!this.screen.op_start_time) {
        return false
      }
      return true
    },
    getSelectedGroupMemberGroupID() {
      return this.$store.getters['user/getSelectedGroupMember'].user_has_group.id
    },
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
  },
  created() {
    this.getAllVenues(this.paginationObject)
    this.getCountriesList()
  },
  methods: {
    audienceProfile(data) {
      this.screen.audience_profile = data
    },
    async getAllVenues({ offset, limit, searchQuery }) {
      this.spinner = true
      const payload = {
        offset, limit, searchQuery,
      }
      try {
        const success = await this.$store.dispatch('venues/getAllVenue', { ...payload })

        if (success) {
          const { data } = this.$store.getters['venues/getVenue']
          this.allVenues = data.results
          this.paginationObject.total = data.total
          this.paginationObject.limit = data.limit
          showToast('Fetch Venues', 'Venues fetch successfully', 'success')
          this.spinner = false
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Venues', 'You are not authorized for this action!', 'danger')
          this.spinner = false
        }
      }
    },
    pageChanged(event) {
      this.paginationObject.offset = event
      this.getAllVenues(this.paginationObject)
    },
    async getCountriesList() {
      await this.$store.dispatch('devices/getAllCountries')
    },
    reloadVenue() {
      this.reloadVenues(this.paginationObject)
    },
    compareTimes() {
      const [h1, m1, s1] = this.screen.op_start_time.split(':').map(Number)
      const [h2, m2, s2] = this.screen.op_end_time.split(':').map(Number)
      const date1 = new Date()
      date1.setHours(h1, m1, s1, 0)

      const date2 = new Date()
      date2.setHours(h2, m2, s2, 0)

      if (date1.getTime() > date2.getTime()) {
        this.endTimer = false
        this.startTimer = true
      } else if (date1.getTime() < date2.getTime()) {
        this.endTimer = true
        this.startTimer = true
      } else {
        this.result = 'Time 1 is equal to Time 2'
        this.endTimer = true
        this.startTimer = true
      }
    },
    async reloadVenues({ offset, limit, searchQuery }) {
      const payload = {
        offset, limit, searchQuery,
      }
      try {
        const success = await this.$store.dispatch('venues/getAllVenue', { ...payload })

        if (success) {
          const { data } = this.$store.getters['venues/getVenue']
          this.allVenues = data.results
          this.paginationObject.total = data.total
          this.paginationObject.limit = data.limit
          showToast('Fetch Venues', 'Venues fetch successfully', 'success')
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Venues', 'You are not authorized for this action!', 'danger')
        }
      }
    },
    async validationForm() {
      try {
        const success = await this.$refs.screenForm.validate()
        if (success) {
          this.createMacAddress()
          this.submitData = true
          // eslint-disable-next-line
          const [hours, minutes] = this.screen.op_start_time.split(':')
          this.screen.op_start_time = `${hours}:${minutes}`
          const [hours1, minutes1] = this.screen.op_end_time.split(':')
          this.screen.op_end_time = `${hours1}:${minutes1}`
          const payload = { ...this.screen }
          const result = await this.$store.dispatch('devices/createDevice', { payload })
          if (result.data.statusCode === 201) {
            this.submitData = false
            showToast('Screen', 'Screen have been created successfully', 'success')
            this.$router.push('/venues')
          }
        } else {
          this.submitData = false
          this.$swal('Enter valid data')
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if ((typeof message === 'string' && message === 'Device limit exceeded!') || message[0].toString() === 'Device limit exceeded!') {
          if (this.getSelectedGroupMember.user_has_group.package.trial_month_count === 0) {
            this.$swal.fire({
              title: 'Screen Limit Exceeded!',
              text: 'To add this screen upgrade your subscription',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Upgrade',
              cancelButtonText: 'Cancel',
              reverseButtons: true,
              didOpen: () => {
                const swalPopup = document.querySelector('.swal2-popup')
                swalPopup.style.width = '550px'
              },
            }).then(result => {
              if (result.isConfirmed) {
                window.open(
                  `${process.env.VUE_APP_SIMI_CONNECT_BASE_URL}edit-packages/${this.getSelectedGroupMemberGroupID}`,
                  '_blank',
                )
              }
            })
          } else {
            this.$swal.fire({
              title: 'Screen Limit Exceeded!',
              text: 'To add this screen upgrade your subscription',
              icon: 'warning',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Cancel',
              reverseButtons: true,
              didOpen: () => {
                const swalPopup = document.querySelector('.swal2-popup')
                swalPopup.style.width = '550px'
              },
            })
          }
        } else {
          showToast('Create Screen', message, 'danger')
        }
      } finally {
        this.submitData = false
      }
    },
    openVenueModal() {
      this.$refs['add-venue'].show()
    },
    createMacAddress() {
      let result = ''
      let macAddres = ''
      for (let i = this.stringLength; i > 0; i -= 1) { result += this.stringCharacters[Math.floor(Math.random() * this.stringCharacters.length)] }
      macAddres = result + Date.now()
      this.screen.mac_address = macAddres
    },
  },
}

</script>
