var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(_vm.spinner)?_c('b-skeleton-wrapper',{attrs:{"loading":_vm.spinner},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton',{attrs:{"type":"button"}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1),_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1)],1),_c('b-skeleton',{staticClass:"mt-1",attrs:{"type":"button"}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1),_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"lg":"12","xl":"12","md":"12","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1),_c('b-col',{staticClass:"mt-1 mb-1",attrs:{"cols":"12"}},[_c('b-skeleton',{attrs:{"type":"button"}})],1),_c('b-col',{attrs:{"lg":"6","md":"6","xl":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1),_c('b-col',{attrs:{"lg":"6","md":"6","xl":"6","sm":"12"}},[_c('b-skeleton',{attrs:{"type":"input"}})],1)],1)]},proxy:true}],null,false,1429669415)}):_c('div',[_c('ValidationObserver',{ref:"screenForm"},[_c('h3',{staticClass:"font-weight-bold mb-1"},[_vm._v(" Basic Information ")]),_c('b-form-row',[_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Screen name","vid":"screen-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"screen-name","label":"Enter Screen Name"}},[_c('b-form-input',{attrs:{"id":"screen-name","placeholder":"e.g Black 32 inch Samsung","variant":"primary"},model:{value:(_vm.screen.name),callback:function ($$v) {_vm.$set(_vm.screen, "name", $$v)},expression:"screen.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6","xl":"6","md":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Screen Venue","vid":"venue-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Select Venue","label-for":"venue-type"}},[_c('v-select',{attrs:{"input-id":"venue-type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.getters['venues/getVenue'].data.results,"state":errors.length > 0 ? false:null,"label":"venue_name","reduce":function (allVenues) { return allVenues.id; },"placeholder":"Select Venue"},scopedSlots:_vm._u([{key:"list-footer",fn:function(){return [_c('b-row',{staticClass:"justify-content-center mx-auto"},[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0 ml-5",attrs:{"value":_vm.paginationObject.offset,"total-rows":_vm.paginationObject.total,"per-page":_vm.paginationObject.limit,"hide-goto-end-buttons":"","size":"sm","pills":""},on:{"change":function($event){return _vm.pageChanged($event)}}})],1)],1)]},proxy:true}],null,true),model:{value:(_vm.screen.venueId),callback:function ($$v) {_vm.$set(_vm.screen, "venueId", $$v)},expression:"screen.venueId"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" Screen Operating Hours ")])]),_c('b-col',{attrs:{"lg":"6","md":"6","xl":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Start Time","vid":"screen-start-time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"screen-start-time","label":"Enter Start Time"}},[_c('b-form-timepicker',{attrs:{"id":"screen-start-time","placeholder":"Screen Start Time","variant":"primary","locale":"en","show-seconds":false,"state":_vm.startTimer,"hour12":false},model:{value:(_vm.screen.op_start_time),callback:function ($$v) {_vm.$set(_vm.screen, "op_start_time", $$v)},expression:"screen.op_start_time"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6","md":"6","xl":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"End Time","vid":"screen-end-time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"screen-end-time","label":"Enter End Time"}},[_c('b-form-timepicker',{attrs:{"id":"screen-end-time","placeholder":"Screen End Time","variant":"primary","locale":"en","show-seconds":false,"state":_vm.endTimer,"hour12":false,"disabled":!_vm.endTimeField},on:{"input":_vm.compareTimes},model:{value:(_vm.screen.op_end_time),callback:function ($$v) {_vm.$set(_vm.screen, "op_end_time", $$v)},expression:"screen.op_end_time"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" Monetisation ")])]),_c('b-col',{attrs:{"lg":"4","xl":"4","md":"4"}},[_c('b-form-checkbox',{attrs:{"variant":"primary","name":"static-support","value":true,"unchecked-value":false},model:{value:(_vm.screen.ssp_opt_in),callback:function ($$v) {_vm.$set(_vm.screen, "ssp_opt_in", $$v)},expression:"screen.ssp_opt_in"}},[_vm._v(" Monetisation ")])],1),_c('b-col',{staticClass:"bg-white mt-2",attrs:{"cols":"12"}},[_c('CardQuillEditor',{attrs:{"name":"Screen Audience Profile"},on:{"audienceProfile":_vm.audienceProfile}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","offset":"12","align-items-end":"","align-self":"end"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"float":"right"},attrs:{"filled":"","variant":"primary","disabled":_vm.submitData},on:{"click":function($event){return _vm.validationForm()}}},[(_vm.submitData)?_c('div',{staticClass:"spinner"},[_c('b-spinner',{staticClass:"d-inline-block",attrs:{"small":""}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])],1):_vm._e(),_vm._v(" Submit ")])],1)],1)],1)],1),_c('AddVenue',{ref:"add-venue",on:{"reloadVenues":function($event){return _vm.reloadVenue()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }